import store from '@/store';

async function fetchRights() {
  if (window.vue.$store.state.user) {
    const response = await window.vue.$http.get(
      `users/details/${window.vue.$store.state.user.element_id}`
    );
    window.vue.$store.commit('setUser', response.user);
    window.vue.$store.commit('setRights', response.rights);
    window.vue.$store.commit('setUserConfig', response.user?.config);
  }
}

async function requireAuth(to, from, next) {
  try {
    fetchRights();
    if (!window.vue.$store.state.token) {
      throw '';
    }

    const response = await window.vue.$http.get('auth/validate');

    if (response.status == 'OK') {
      window.vue.$store.commit('setUser', response.user);
      window.vue.$store.commit('setRights', response.rights);
      window.vue.$store.commit('setFeatures', response.features);
      window.vue.$store.commit('fields/set', response.fields);
      window.vue.$store.commit('setUserConfig', response.user?.config);
      next();
    } else {
      throw 'Kérjük jelentkezzen be újra!';
    }
  } catch (e) {
    next({
      name: 'Login',
      params: {
        message: e,
        redirect: to.fullPath,
      },
    });
  }
}

import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const AccessLevelEditor = () =>
  import(/* webpackChunkName: "access-level-editor" */ '@/pages/access-levels/Editor.vue');
const AccessLevelList = () =>
  import(/* webpackChunkName: "access-level-list" */ '@/pages/access-levels/List.vue');
const AdminLog = () => import(/* webpackChunkName: "admin-log" */ '@/pages/logs/AdminLog.vue');
const BarrierEditor = () =>
  import(/* webpackChunkName: "barrier-editor" */ '@/pages/barriers/Editor.vue');
const BarrierList = () =>
  import(/* webpackChunkName: "barrier-list" */ '@/pages/barriers/List.vue');
const BuildingEditor = () =>
  import(/* webpackChunkName: "building-editor" */ '@/pages/buildings/Editor.vue');
const BuildingList = () =>
  import(/* webpackChunkName: "building-list" */ '@/pages/buildings/List.vue');
const BuildingLevelEditor = () =>
  import(/* webpackChunkName: "building-level-editor" */ '@/pages/building-levels/Editor.vue');
const BuildingLevelList = () =>
  import(/* webpackChunkName: "building-level-list" */ '@/pages/building-levels/List.vue');
const BuildingLog = () =>
  import(/* webpackChunkName: "building-log" */ '@/pages/daemon_log/building_log.vue');
const CameraList = () => import(/* webpackChunkName: "camera-list" */ '@/pages/cameras/List.vue');
const CameraEditor = () =>
  import(/* webpackChunkName: "camera-editor" */ '@/pages/cameras/Editor.vue');
const CardEditor = () => import(/* webpackChunkName: "card-editor" */ '@/pages/cards/Editor.vue');
const CardGroupEditor = () =>
  import(/* webpackChunkName: "card-group-editor" */ '@/pages/cardgroup/cardgroup.vue');
const CardGroupList = () =>
  import(/* webpackChunkName: "card-group-list" */ '@/pages/cardgroup/cardgroups.vue');
const CardList = () => import(/* webpackChunkName: "card-list" */ '@/pages/cards/List.vue');
const CardOwnerEditor = () =>
  import(/* webpackChunkName: "card-owner-editor" */ '@/pages/card-owners/Editor');
const CardOwnerList = () =>
  import(/* webpackChunkName: "card-owner-list" */ '@/pages/card-owners/List');
const ChangePassword = () =>
  import(/* webpackChunkName: "change-password" */ '@/pages/profil/change_password.vue');
const ConnectionEditor = () =>
  import(/* webpackChunkName: "connection-editor" */ '@/pages/connections/Editor.vue');
const ConnectionList = () =>
  import(/* webpackChunkName: "connection-list" */ '@/pages/connections/List.vue');
const ControllerEditor = () =>
  import(/* webpackChunkName: "controller-editor" */ '@/pages/controllers/Editor.vue');
const ControllerList = () =>
  import(/* webpackChunkName: "controller-list" */ '@/pages/controllers/List.vue');
const DaemonLog = () =>
  import(/* webpackChunkName: "daemon-log" */ '@/pages/daemon_log/daemon_log.vue');
const DateIntervalEditor = () =>
  import(/* webpackChunkName: "date-interval-editor" */ '@/pages/date-intervals/Editor.vue');
const DateIntervalList = () =>
  import(/* webpackChunkName: "date-interval-list" */ '@/pages/date-intervals/List.vue');
const DoorEditor = () => import(/* webpackChunkName: "door-editor" */ '@/pages/doors/Editor.vue');
const DoorList = () => import(/* webpackChunkName: "door-list" */ '@/pages/doors/List.vue');
const Filter = () => import(/* webpackChunkName: "filter" */ '@/pages/filter/List.vue');
const GatewayEditor = () =>
  import(/* webpackChunkName: "gateway-editor" */ '@/pages/gateways/Editor.vue');
const GatewayList = () =>
  import(/* webpackChunkName: "gateway-list" */ '@/pages/gateways/List.vue');
const GroupEditor = () =>
  import(/* webpackChunkName: "group-editor" */ '@/pages/groups/Editor.vue');
const GroupList = () => import(/* webpackChunkName: "group-list" */ '@/pages/groups/List.vue');
const GuestLogList = () => import(/* webpackChunkName: "guest-log" */ '@/pages/guest-log/List.vue');
const Home = () => import(/* webpackChunkName: "home" */ '@/pages/home/Home.vue');
const Login = () => import(/* webpackChunkName: "login" */ '@/pages/login/Login.vue');
const Logout = () => import(/* webpackChunkName: "logout" */ '@/pages/logout/logout.vue');
const Message = () => import(/* webpackChunkName: "message" */ '@/pages/message/message.vue');
const OccupantList = () =>
  import(/* webpackChunkName: "occupant-list" */ '@/pages/occupants/List.vue');
const OpeningHours = () =>
  import(/* webpackChunkName: "opening-hours" */ '@/pages/openings/Editor.vue');
const OperationList = () =>
  import(/* webpackChunkName: "operation-list" */ '@/pages/operations/operations.vue');
const ParkingLog = () =>
  import(/* webpackChunkName: "parking-log" */ '@/pages/daemon_log/parking_log.vue');
const ParkingTicketsList = () =>
  import(/* webpackChunkName: "parking-tickets-list" */ '@/pages/parking-tickets/List.vue');
const PlateEditor = () => import(/* webpackChunkName: "plate-editor" */ '@/pages/plates/Editor');
const PlateList = () => import(/* webpackChunkName: "plate-list" */ '@/pages/plates/List');
const QrCodeList = () => import(/* webpackChunkName: "qr-code-list" */ '@/pages/qr-codes/List.vue');
const QrCodeRequestList = () =>
  import(/* webpackChunkName: "qr-code-request-list" */ '@/pages/qr-codes/requests/List.vue');
const QrCodeRequestEditor = () =>
  import(/* webpackChunkName: "qr-code-request-editor" */ '@/pages/qr-codes/requests/Editor.vue');
const QrCodeApprovalList = () =>
  import(/* webpackChunkName: "qr-code-approval-list" */ '@/pages/qr-codes/approval/List.vue');
const ReaderEditor = () =>
  import(/* webpackChunkName: "reader-editor" */ '@/pages/readers/Editor.vue');
const ReaderList = () => import(/* webpackChunkName: "reader-list" */ '@/pages/readers/List.vue');
const RelayEditor = () =>
  import(/* webpackChunkName: "relay-editor" */ '@/pages/relays/Editor.vue');
const RelayList = () => import(/* webpackChunkName: "relay-list" */ '@/pages/relays/List.vue');
const RemoteOpen = () =>
  import(/* webpackChunkName: "remote-open" */ '@/pages/remote_open/RemoteOpen.vue');
const ScheduleEditor = () =>
  import(/* webpackChunkName: "schedule-editor" */ '@/pages/schedule-groups/Editor.vue');
const ScheduleList = () =>
  import(/* webpackChunkName: "schedule-list" */ '@/pages/schedule-groups/List.vue');
const Status = () => import(/* webpackChunkName: "status" */ '@/pages/status/status.vue');
const TurnstileEditor = () =>
  import(/* webpackChunkName: "turnstile-editor" */ '@/pages/turnstiles/Editor.vue');
const TurnstileList = () =>
  import(/* webpackChunkName: "turnstile-list" */ '@/pages/turnstiles/List.vue');
const UserEditor = () => import(/* webpackChunkName: "user-editor" */ '@/pages/users/Editor');
const UsersList = () => import(/* webpackChunkName: "users-list" */ '@/pages/users/List');
const ZoneEditor = () => import(/* webpackChunkName: "zone-editor" */ '@/pages/zones/Editor.vue');
const ZoneList = () => import(/* webpackChunkName: "zone-list" */ '@/pages/zones/List.vue');

const Features = () => import(/* webpackChunkName: "features" */ '@/pages/superadmin/Features.vue');
const DatabaseRepair = () =>
  import(/* webpackChunkName: "database-repair" */ '@/pages/superadmin/DatabaseRepair.vue');
const Map = () => import(/* webpackChunkName: "map" */ '@/pages/map/Map.vue');
const Installer = () => import(/* webpackChunkName: "installer" */ '@/pages/Installer.vue');
const Services = () => import(/* webpackChunkName: "services" */ '@/pages/services.vue');

import DashboardLayout from '@/layouts/DashboardLayout';
import EmptyLayout from '@/layouts/EmptyLayout';

let defaultRoutes = [
  // {
  //   path: '/pricing',
  //   component: () => import( /* webpackChunkName: "map" */ '@/pages/Pricing.vue'),
  //   name: 'Pricing',
  // },
  {
    path: '/map',
    component: Map,
    name: 'Map',
  },
  // {
  //   path: '/install',
  //   component: Installer,
  //   name: 'Installer',
  // },
  {
    path: '/login',
    component: Login,
    name: 'Login',
  },
  {
    path: '/logout',
    component: Logout,
    name: 'Logout',
  },
  {
    path: '/status',
    component: Status,
    name: 'Status',
  },
  {
    path: '/',
    component: DashboardLayout,
    beforeEnter: requireAuth,
    name: 'DashboardLayout',
    children: [
      {
        path: 'msg',
        component: Message,
        name: 'Message',
      },
      {
        path: '',
        component: EmptyLayout,
        children: [
          {
            path: 'home',
            component: Home,
            name: 'Home',
          },
          {
            path: '',
            redirect: { name: 'Home' },
          },
        ],
      },
      {
        path: 'superadmin',
        name: 'Superadmin',
        component: EmptyLayout,
        redirect: 'superadmin/config',
        children: [
          {
            path: 'features',
            component: Features,
            name: 'Features',
          },
          {
            path: 'database-repair',
            component: DatabaseRepair,
            name: 'DatabaseRepair',
          },
        ],
      },
      {
        path: 'access-levels',
        name: 'AccessLevels',
        component: EmptyLayout,
        redirect: 'access-levels/list',
        children: [
          {
            path: 'list',
            component: AccessLevelList,
            name: 'AccessLevelList',
          },
          {
            path: 'new',
            component: AccessLevelEditor,
            name: 'NewAccessLevel',
          },
          {
            path: 'edit/:id',
            component: AccessLevelEditor,
            name: 'AccessLevelEditor',
          },
        ],
      },
      {
        path: 'qr-codes',
        name: 'QrCodes',
        component: EmptyLayout,
        redirect: 'qr-codes/list',
        children: [
          {
            path: 'list',
            component: QrCodeList,
            name: 'QrCodeList',
          },
          {
            path: 'approval',
            component: QrCodeApprovalList,
            name: 'QrCodeApprovalList',
          },
          {
            path: 'requests',
            component: EmptyLayout,
            redirect: 'requests/list',
            children: [
              {
                path: 'list',
                component: QrCodeRequestList,
                name: 'QrCodeRequestList',
              },
              {
                path: 'edit/:id',
                component: QrCodeRequestEditor,
                name: 'QrCodeRequestEditor',
              },
            ],
          },
        ],
      },
      {
        path: 'card-owners',
        name: 'CardOwners',
        component: EmptyLayout,
        redirect: 'card-owners/list',
        children: [
          {
            path: 'list',
            component: CardOwnerList,
            name: 'CardOwnerList',
          },
          {
            path: 'edit/:id',
            component: CardOwnerEditor,
            name: 'CardOwnerEditor',
          },
          {
            path: 'new',
            component: CardOwnerEditor,
            name: 'NewCardOwner',
          },
        ],
      },
      {
        path: 'cards',
        name: 'Cards',
        component: EmptyLayout,
        redirect: 'cards/list',
        children: [
          {
            path: 'list',
            component: CardList,
            name: 'CardList',
          },
          {
            path: 'new',
            component: CardEditor,
            name: 'NewCard',
          },
          {
            path: 'edit/:id',
            component: CardEditor,
            name: 'CardEditor',
          },
        ],
      },
      {
        path: 'card-groups',
        name: 'CardGroups',
        component: EmptyLayout,
        redirect: 'card-groups/list',
        children: [
          {
            path: 'list',
            component: CardGroupList,
            name: 'CardGroupList',
          },
          {
            path: 'edit',
            component: CardGroupEditor,
            name: 'CardGroupEditor',
          },
        ],
      },
      {
        path: 'change-password',
        component: EmptyLayout,
        children: [
          {
            path: '',
            component: ChangePassword,
            name: 'ChangePassword',
          },
        ],
      },
      {
        path: 'date-intervals',
        name: 'DateIntervals',
        component: EmptyLayout,
        redirect: 'date-intervals/list',
        children: [
          {
            path: 'list',
            component: DateIntervalList,
            name: 'DateIntervalList',
          },
          {
            path: 'new',
            component: DateIntervalEditor,
            name: 'NewDateInterval',
          },
          {
            path: 'edit/:id',
            component: DateIntervalEditor,
            name: 'DateIntervalEditor',
          },
        ],
      },
      {
        path: 'devices',
        name: 'Devices',
        component: EmptyLayout,
        redirect: 'devices/buildings',
        children: [
          {
            path: 'building-levels',
            name: 'BuildingLevels',
            component: EmptyLayout,
            redirect: 'building-levels/list',
            children: [
              {
                path: 'list',
                component: BuildingLevelList,
                name: 'BuildingLevelList',
              },
              {
                path: 'edit/:id',
                component: BuildingLevelEditor,
                name: 'BuildingLevelEditor',
              },
              {
                path: 'new',
                component: BuildingLevelEditor,
                name: 'NewBuildingLevel',
              },
            ],
          },
          {
            path: 'buildings',
            name: 'Buildings',
            component: EmptyLayout,
            redirect: 'buildings/list',
            children: [
              {
                path: 'list',
                component: BuildingList,
                name: 'BuildingList',
              },
              {
                path: 'edit/:id',
                component: BuildingEditor,
                name: 'BuildingEditor',
              },
              {
                path: 'new',
                component: BuildingEditor,
                name: 'NewBuilding',
              },
            ],
          },
          {
            path: 'gateways',
            name: 'Gateways',
            component: EmptyLayout,
            redirect: 'gateways/list',
            children: [
              {
                path: 'list',
                component: GatewayList,
                name: 'GatewayList',
              },
              {
                path: 'edit/:id',
                component: GatewayEditor,
                name: 'GatewayEditor',
              },
              {
                path: 'new',
                component: GatewayEditor,
                name: 'NewGateway',
              },
            ],
          },
          {
            path: 'connections',
            name: 'Connections',
            component: EmptyLayout,
            redirect: 'connections/list',
            children: [
              {
                path: 'list',
                component: ConnectionList,
                name: 'ConnectionList',
              },
              {
                path: 'edit/:id',
                component: ConnectionEditor,
                name: 'ConnectionEditor',
              },
              {
                path: 'new',
                component: ConnectionEditor,
                name: 'NewConnection',
              },
            ],
          },
          {
            path: 'controllers',
            name: 'Controllers',
            component: EmptyLayout,
            redirect: 'controllers/list',
            children: [
              {
                path: 'list',
                component: ControllerList,
                name: 'ControllerList',
              },
              {
                path: 'edit/:id',
                component: ControllerEditor,
                name: 'ControllerEditor',
              },
              {
                path: 'new',
                component: ControllerEditor,
                name: 'NewController',
              },
            ],
          },
          {
            path: 'readers',
            name: 'Readers',
            component: EmptyLayout,
            redirect: 'devices/readers/list',
            children: [
              {
                path: 'list',
                component: ReaderList,
                name: 'ReaderList',
              },
              {
                path: 'edit/:id',
                component: ReaderEditor,
                name: 'ReaderEditor',
              },
              {
                path: 'new',
                component: ReaderEditor,
                name: 'NewReader',
              },
            ],
          },
          {
            path: 'relays',
            name: 'Relays',
            component: EmptyLayout,
            redirect: 'devices/relays/list',
            children: [
              {
                path: 'list',
                component: RelayList,
                name: 'RelayList',
              },
              {
                path: 'edit/:id',
                component: RelayEditor,
                name: 'RelayEditor',
              },
              {
                path: 'new',
                component: RelayEditor,
                name: 'NewRelay',
              },
            ],
          },
          {
            path: 'doors',
            name: 'Doors',
            component: EmptyLayout,
            redirect: 'doors/list',
            children: [
              {
                path: 'list',
                component: DoorList,
                name: 'DoorList',
              },
              {
                path: 'edit/:id',
                component: DoorEditor,
                name: 'DoorEditor',
              },
              {
                path: 'new',
                component: DoorEditor,
                name: 'NewDoor',
              },
            ],
          },
          {
            path: 'barriers',
            name: 'Barriers',
            component: EmptyLayout,
            redirect: 'barriers/list',
            children: [
              {
                path: 'list',
                component: BarrierList,
                name: 'BarrierList',
              },
              {
                path: 'edit/:id',
                component: BarrierEditor,
                name: 'BarrierEditor',
              },
              {
                path: 'new',
                component: BarrierEditor,
                name: 'NewBarrier',
              },
            ],
          },
          {
            path: 'turnstiles',
            name: 'Turnstiles',
            component: EmptyLayout,
            redirect: 'turnstiles/list',
            children: [
              {
                path: 'list',
                component: TurnstileList,
                name: 'TurnstileList',
              },
              {
                path: 'edit/:id',
                component: TurnstileEditor,
                name: 'TurnstileEditor',
              },
              {
                path: 'new',
                component: TurnstileEditor,
                name: 'NewTurnstile',
              },
            ],
          },
          {
            path: 'cameras',
            name: 'Cameras',
            component: EmptyLayout,
            redirect: 'cameras/list',
            children: [
              {
                path: 'list',
                component: CameraList,
                name: 'CameraList',
              },
              {
                path: 'edit/:id',
                component: CameraEditor,
                name: 'CameraEditor',
              },
              {
                path: 'new',
                component: CameraEditor,
                name: 'NewCamera',
              },
            ],
          },
        ],
      },
      {
        path: 'zones',
        name: 'Zones',
        component: EmptyLayout,
        redirect: 'zones/list',
        children: [
          {
            path: 'list',
            component: ZoneList,
            name: 'ZoneList',
          },
          {
            path: 'edit/:id',
            component: ZoneEditor,
            name: 'ZoneEditor',
          },
          {
            path: 'new',
            component: ZoneEditor,
            name: 'NewZone',
          },
        ],
      },
      {
        path: 'occupants',
        name: 'Occupants',
        component: EmptyLayout,
        redirect: 'occupants/list',
        children: [
          {
            path: 'list',
            component: OccupantList,
            name: 'OccupantList',
          },
        ],
      },
      {
        path: 'guest-log',
        name: 'GuestLog',
        component: EmptyLayout,
        redirect: 'guest-log/list',
        children: [
          {
            path: 'list',
            component: GuestLogList,
            name: 'GuestLogList',
          },
        ],
      },
      {
        path: 'groups',
        name: 'Groups',
        component: EmptyLayout,
        redirect: 'groups/list',
        children: [
          {
            path: 'list',
            component: GroupList,
            name: 'GroupList',
          },
          {
            path: 'new',
            component: GroupEditor,
            name: 'NewGroup',
          },
          {
            path: 'edit/:id',
            component: GroupEditor,
            name: 'GroupEditor',
          },
        ],
      },
      {
        path: 'log',
        name: 'Log',
        component: EmptyLayout,
        redirect: 'log/building',
        children: [
          {
            path: 'admin',
            component: AdminLog,
            name: 'AdminLog',
          },
          {
            path: 'building',
            component: BuildingLog,
            name: 'BuildingLog',
            params: {
              type: 'Building',
            },
          },
          {
            path: 'parking',
            component: ParkingLog,
            name: 'ParkingLog',
            params: {
              type: 'Parkinglot',
            },
          },
        ],
      },
      {
        path: 'opening',
        component: EmptyLayout,
        children: [
          {
            path: '',
            component: OpeningHours,
            name: 'OpeningHours',
          },
        ],
      },
      {
        path: 'operations',
        component: EmptyLayout,
        children: [
          {
            path: '',
            component: OperationList,
            name: 'OperationList',
          },
        ],
      },
      {
        path: 'plates',
        name: 'Plates',
        component: EmptyLayout,
        redirect: 'plates/list',
        children: [
          {
            path: 'list',
            component: PlateList,
            name: 'PlateList',
          },
          {
            path: 'new',
            component: PlateEditor,
            name: 'NewPlate',
          },
          {
            path: 'edit/:id',
            component: PlateEditor,
            name: 'PlateEditor',
          },
        ],
      },
      {
        path: 'remote-open',
        component: EmptyLayout,
        children: [
          {
            path: '',
            component: RemoteOpen,
            name: 'RemoteOpen',
          },
        ],
      },
      {
        path: 'schedules',
        name: 'Schedules',
        component: EmptyLayout,
        redirect: 'schedules/list',
        children: [
          {
            path: 'list',
            component: ScheduleList,
            name: 'ScheduleList',
          },
          {
            path: 'new',
            component: ScheduleEditor,
            name: 'NewSchedule',
          },
          {
            path: 'edit/:id',
            component: ScheduleEditor,
            name: 'ScheduleEditor',
          },
        ],
      },
      {
        path: 'tickets',
        name: 'Tickets',
        component: EmptyLayout,
        redirect: 'tickets/list',
        children: [
          {
            path: 'list',
            component: ParkingTicketsList,
            name: 'TicketList',
          },
        ],
      },
      {
        path: 'users',
        name: 'Users',
        component: EmptyLayout,
        redirect: 'users/list',
        children: [
          {
            path: 'new',
            component: UserEditor,
            name: 'NewUser',
          },
          {
            path: 'edit/:id',
            component: UserEditor,
            name: 'UserEditor',
          },
          {
            path: 'list',
            component: UsersList,
            name: 'UserList',
          },
          {
            path: 'filter',
            component: Filter,
            name: 'Filter',
          },
        ],
      },

      {
        path: 'keys',
        name: 'Keys',
        component: EmptyLayout,
        redirect: 'keys/list',
        children: [
          {
            path: 'new',
            component: () => import(/* webpackChunkName: "KeyEditor" */ '@/pages/keys/Editor.vue'),
            name: 'NewKey',
            meta: {
              title: 'Kulcs létrehozása',
            },
          },
          {
            path: 'edit/:id',
            component: () => import(/* webpackChunkName: "KeyEditor" */ '@/pages/keys/Editor.vue'),
            name: 'KeyEditor',
            meta: {
              title: 'Kulcs',
            },
          },
          {
            path: 'list',
            component: () => import(/* webpackChunkName: "KeyList" */ '@/pages/keys/List.vue'),
            name: 'KeyList',
            meta: {
              title: 'Kulcsok',
            },
          },
          {
            path: 'log',
            component: () => import(/* webpackChunkName: "KeysLog" */ '@/pages/keys-log/List.vue'),
            name: 'KeysLog',
            meta: {
              title: 'Kulcs napló',
            },
          },
          {
            path: 'handling',
            component: () =>
              import(/* webpackChunkName: "KeyHandling" */ '@/pages/key-handling/KeyHandling.vue'),
            name: 'KeyHandling',
            meta: {
              title: 'Kulcskezelés',
            },
          },
          {
            path: 'reservations',
            name: 'KeyReservations',
            component: EmptyLayout,
            redirect: 'keys/reservations/calendar',
            children: [
              {
                path: 'calendar',
                component: () =>
                  import(
                    /* webpackChunkName: "KeyReservationsCalendar" */ '@/pages/key-reservations/Calendar.vue'
                  ),
                name: 'KeyReservationsCalendar',
                meta: {
                  title: 'Kulcsfoglalás',
                },
              },
            ],
          },
          {
            path: 'cabinets',
            name: 'KeyKabinets',
            component: EmptyLayout,
            redirect: 'keys/cabinets/list',
            children: [
              {
                path: 'new',
                component: () =>
                  import(
                    /* webpackChunkName: "KeyCabinetEditor" */ '@/pages/key-cabinets/Editor.vue'
                  ),
                name: 'NewKeyCabinet',
                meta: {
                  title: 'Kulcsszekrény létrehozása',
                },
              },
              {
                path: 'edit/:id',
                component: () =>
                  import(
                    /* webpackChunkName: "KeyCabinetEditor" */ '@/pages/key-cabinets/Editor.vue'
                  ),
                name: 'KeyCabinetEditor',
                meta: {
                  title: 'Kulcsszekrény',
                },
              },
              {
                path: 'list/:highlights?',
                component: () =>
                  import(/* webpackChunkName: "KeyCabinetList" */ '@/pages/key-cabinets/List.vue'),
                name: 'KeyCabinetList',
                meta: {
                  title: 'Kulcsszekrények',
                },
              },
            ],
          },
        ],
      },

      {
        path: 'services',
        component: Services,
        name: 'Services',
      },

      {
        path: '',
        component: Home,
        beforeEnter: requireAuth,
      },
      {
        path: '*',
        component: Home,
      },
    ],
  },
];

// const overwriteRoute = (newRoute, routes = defaultRoutes) => {
//   for (let route of routes) {
//     if (route.name === newRoute.name) {
//       route = newRoute;
//       console.log('overwritten', route, '->', newRoute);
//       return route;
//     } else if (route.children) {
//       route.children = overwriteRoute(newRoute, route.children);
//     }
//   }
//   return routes;
// };

Array.prototype.forEachRecursive = function (callback) {
  for (const item of [].concat(this)) {
    callback(item);
    if (item.children) {
      item.children.forEachRecursive(callback);
    }
  }
};

const overwriteRoute = (newRoute, routes = defaultRoutes) => {
  for (const route of routes) {
    if (route.name === newRoute.name) {
      for (const [key, value] of Object.entries(newRoute)) {
        route[key] = value;
      }
      return true;
    } else if (route.children) {
      if (overwriteRoute(newRoute, route.children)) {
        return true;
      }
    }
  }
  return false;
};

// const deleteDuplicatedRoutes = (routes, newRoutes) => {
//   for (const route of newRoutes) {

//   }
// };

// const createRouter = () => new Router({
//   base: process.env.BASE_URL || '',
//   mode: 'history',
//   routes
// })

// const router = createRouter()

const router = new VueRouter({
  base: process.env.BASE_URL || '',
  mode: 'history',
  routes: defaultRoutes,
});

router.$addRoutes = (newRoutes) => {
  router.matcher = new VueRouter({
    mode: 'history',
  }).matcher;

  for (const route of newRoutes) {
    const overwritten = overwriteRoute(route);
    // console.log(route, overwritten);
    if (!overwritten) {
      defaultRoutes = [...defaultRoutes, route];
    }
  }

  for (const route of defaultRoutes) {
    router.addRoute(route);
  }
};

// Wait for vuex store to restore states
router.beforeEach(async (to, from, next) => {
  await store.restored;
  next();
});

export { overwriteRoute };

export default router;
